<template>
  <div class="compte">
    <div class="int">
      
      <div class="nav_left">
        <router-link tag="div" :to="{ name: 'user_compte_commandes' }" class="elem" :class="{ active: this.$route.name === 'user_compte_commandes' }">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" /></svg>
          Mes Commandes</router-link>
        <router-link tag="div" :to="{ name: 'user_compte_password' }" class="elem" :class="{ active: this.$route.name === 'user_compte_password' }">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" /></svg>
          Changer mon mot de passe</router-link>
      </div>
  
  
      <div class="right">
        <transition name="slide2-fade">
          <router-view></router-view>
        </transition>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style>
  .compte { min-height: calc(var(--vh, 1vh) * 100); background: #f5f5f5 }
  .compte .int { display: flex; flex-direction: column; }
  .compte .nav_left { }
  .compte .nav_left .elem { font-size: 16px; display: flex; align-items: center; font-weight: 400; height: 56px; padding: 0 20px; background: #fff; margin-bottom: 1px; cursor: pointer }
  .compte .nav_left .elem svg { margin-right: 16px; }
  .compte .nav_left .elem.active { border-left: 4px solid var(--orange); background: #fbfbfb; padding-left: 16px; }
  .compte .right { margin-top: 36px; }
  
  .compte .title_a { height: 56px; background: #fff; padding: 0 16px; display: flex; align-items: center }
  .compte .title_a svg { width: 30px; height: 30px; margin-right: 10px; }
  .compte .title {  }


  @media only screen and (min-width: 800px) {
    .compte .int { flex-direction: row; width: 960px; margin: 0 auto; padding: 60px 0; }
    .compte .nav_left { width: 30%; min-width: 30%; }
    .compte .right { width: 70%; padding-left: 20px; margin-top: 0; }
    
  }
</style>